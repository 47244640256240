'use client'

import { Pagination } from 'swiper/modules'
import { Swiper, SwiperSlide } from 'swiper/react'
import { SWIPER_SETTING } from '@/consts/swiperSetting'
import type { CancerType } from '@/types/cancer'
import type { Item, SubItems } from '@/types/guide/main'
import LinkToSubGuideButton from './LinkToSubGuideButton'

const settings = {
  ...SWIPER_SETTING,
  modules: [...SWIPER_SETTING.modules, Pagination],
  spaceBetween: 12,
  slidesPerView: 1.36,
  slidesOffsetBefore: 24,
  slidesOffsetAfter: 24,
  focusableElements: 'input',
  pagination: {
    clickable: true,
  },
  breakpoints: {
    600: {
      slidesPerView: 3.2,
      mousewheel: {
        enabled: false,
        forceToAxis: false,
      },
    },
  },
}

const MAX_ITEM_ROWS = 3

/** 소분류 3개씩 그룹핑하는 함수 */
const groupSubGuides = (list: SubItems) => {
  let groupedList = []
  if (list.length > MAX_ITEM_ROWS) {
    for (let i = 0; i < list.length; i += MAX_ITEM_ROWS)
      groupedList.push(list.slice(i, i + MAX_ITEM_ROWS))
    return groupedList
  }
  return []
}

// 주제별 암정보 메인 소분류 리스트
const GuideMainSubGuideList = ({
  item,
  cancerType,
}: {
  item: Item
  cancerType: CancerType
}) => {
  const renderColumn = (item: SubItems, index: number) => {
    return (
      <SwiperSlide key={index}>
        {item.map((guide) => (
          <LinkToSubGuideButton
            key={guide.id + guide.title}
            subGuideLabel={guide.title}
            midGuideId={guide.id}
            cancerType={cancerType}
          />
        ))}
      </SwiperSlide>
    )
  }

  const guideBannerItemsLength = item.child.length || 0

  if (item && guideBannerItemsLength <= MAX_ITEM_ROWS) {
    const bannerItems =
      guideBannerItemsLength === 0
        ? [{ id: '', title: item.title }]
        : item.child
    return (
      <div className="flex flex-col px-md">
        {bannerItems?.map((item: (typeof bannerItems)[number], index) => (
          <LinkToSubGuideButton
            key={`guide-main-banner-item-${index}`}
            subGuideLabel={item?.title ?? ''}
            midGuideId={item.id}
            cancerType={cancerType}
          />
        ))}
      </div>
    )
  }

  return (
    <Swiper
      {...settings}
      // bullet이 swiper 밖에 있도록 설정
      className="[&_.swiper-pagination]:static [&_.swiper-pagination]:mt-2"
    >
      {groupSubGuides(item.child).map((item, index) => {
        return renderColumn(item, index)
      })}
    </Swiper>
  )
}

export default GuideMainSubGuideList
